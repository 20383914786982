.icon-heading-para{
    background-color: #f8f9fa;
    text-align: center;
}
.icon-heading-para p{
    padding: 0 35px 0 35px;
}
.icon-heading-para .feature-box {
    width: 400px;
    height: 200px; /* new height*/
    background: #fff;
    border-radius: 10px;
    /* display: flex; */
    /* align-items: center; */
    display: grid; /* new */
    justify-items: center; /* new */


    text-overflow: ellipsis;
    padding: 15px;
    gap: 15px;
    transition: transform 0.3s ease-in-out;
    
}
.icon-heading-para .feature-box:hover {
    transform: translateY(-5px);
}
.icon-heading-para .feature-box h4 {
    color: #3b3b98;
    font-weight: bold;
    margin-bottom: 5px;
    /* text-align: left; */
}
.icon-heading-para .feature-box p {
    color: #555;
    font-size: 14px;
    /* text-align: center; */

    /*for extra lines dots*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 342px;   
}

.icon-heading-para .icon {
    font-size: 40px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.icon-heading-para .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px; 
}
@media (max-width: 700px) {
    .icon-heading-para .feature-box {
        width: 90%; 
        height: auto;
        flex-direction: row;

}
}