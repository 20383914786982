
.three_section_career11 .card {
    height: 400px;
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;
}

.three_section_career11 .img-container {
    height: 100%;
    padding: 0;
    overflow: hidden;
}

.three_section_career11 .card {
    /* height: 400px; */
    height: 450px;
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;
}

.three_section_career11 .img-container {
    height: 100%;
    padding: 0;
    overflow: hidden;
}
 
@media (max-width: 768px) {
    .three_section_career11 .card {
        height: auto;   
    }
}
@media (max-width: 768px) {
    .three_section_career11 .card {
        height: auto;   
    }
    .three_section_career11 .img-container {
        height: auto;
    }
}