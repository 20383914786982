.hiring-career-section {
    background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
    min-height: 50vh;
    padding: 5rem 1rem;
}

.hiring-career-section .heading {
    font-size: 2.5rem;
    font-weight: 800;
    color: #364aaa;
    margin-bottom: 1.5rem;
    text-align: center;
}

.hiring-career-section .short-para {
    font-size: 1.2rem;
    color: #555;
    /* max-width: 750px; */
    margin: 0 auto 2rem;
    line-height: 1.6;
    text-align: center;
    padding: 0 1rem;
}


.hiring-career-section .interest-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 2rem;
}

.hiring-career-section .skill-card {
    background: #ffffff;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 6px 20px rgba(31, 38, 135, 0.1);
    text-align: center;
}

.hiring-career-section .skill-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(31, 38, 135, 0.15);
}


.hiring-career-section .section-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: #222;
    margin-top: 2rem;
}

.hiring-career-section .list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    padding: 0;
    max-width: 600px;
    margin: auto;
}

.hiring-career-section .list li {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
    list-style: none;
    padding: 8px;
    white-space: nowrap;
    justify-content: center;
    font-weight: 700;
}

.hiring-career-section .list li::before {
    content: "✔";
    color: #007bff;
    font-size: 1rem;
    margin-right: 8px;
}


@media (max-width: 480px) {
    .hiring-career-section .heading {
        font-size: 1.8rem;
    }

    .hiring-career-section .short-para {
        font-size: 0.9rem;
    }

    .hiring-career-section .section-title {
        font-size: 1.5rem;
    }


    .hiring-career-section .list li {
        font-size: 0.9rem;
        padding: 6px;
        align-items: start;
    }
}
