.howwedo_container{
    background-color: #f8f9fa;
    text-align: center;
}
.howwedo_container p{
    padding: 0 35px 0 35px;
}
.howwedo_container .feature-box {
    width: 400px;
    height: 160px;
    background: #eaf4ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 15px;
    transition: transform 0.3s ease-in-out;
}
.howwedo_container .feature-box:hover {
    transform: translateY(-5px);
}
.howwedo_container .feature-box h4 {
    color: #3b3b98;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
}
.howwedo_container .feature-box p {
    color: #555;
    font-size: 14px;
    text-align: left;
}
.howwedo_container .icon {
    font-size: 40px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.howwedo_container .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px; 
}
@media (max-width: 700px) {
    .howwedo_container .feature-box {
        width: 90%; 
        height: auto;
        flex-direction: row;

}
}